<template>
    <div>
        <h2>{{ $t('tiers.menu_juridique') }}</h2>
		<div class="box">
		    <div class="form-group" v-for="client_account in tiers_save.client_accounts" :key="client_account.tiersclientaccount_id">
                <div class="row">
                    <div class="col">
                        <label v-if="client_account.tiersclientaccount_supplier == 1">{{ $t('tiers.compte_client_supplier') }} ({{ client_account.entity.tiers_rs }})</label>
                        <label v-else>{{ $t('tiers.compte_client') }} ({{ client_account.entity.tiers_rs }})</label> : 
                        <b-input-group>
                            <b-form-input
                                v-model="client_account.tiersclientaccount_number"
                                class="form-control col-8 col-md-5 col-lg-2"
                                type="text"
                                :readonly="readonly"
                                @input="on_client_account_change(client_account, $event)"
                            />
                            <template v-slot:append>
                                <button v-if="readonly" class="btn btn-secondary" @click.prevent="change_client_account">{{ $t('global.modifier') }} <font-awesome-icon :icon="['fal', 'edit']"/></button>
                                <template v-else>
                                    <button class="btn btn-secondary" @click.prevent="save_client_account">{{ $t('global.enregistrer') }} <font-awesome-icon :icon="['fal', 'save']"/></button>
                                    <button class="btn btn-secondary" @click.prevent="save_client_account_all(client_account.tiersclientaccount_id)">{{ $t('global.enregistrer_toute_comptas') }} <font-awesome-icon :icon="['fal', 'save']"/></button>
                                </template>
                                <span v-if="!readonly && client_account.is_duplicate" class="ml-2 pt-2">
                                    <font-awesome-icon :icon="['fal', 'exclamation-triangle']"/>
                                    {{ $t('toast.client_account_exists') }}
                                </span>
                            </template>
                        </b-input-group>
                    </div>
                    <div class="col" v-if="client_account.tiersclientaccount_supplier == 0">
                        <label>{{ $t('tiers.vat_code') }} ({{ client_account.entity.tiers_rs }})</label> : 
                        <b-input-group>
                            <e-select
                                id="vat_code"
                                track-by="value"
                                label="label"
                                v-model="client_account.tiersclientaccount_vat_code"
                                :options="vat_code"
                                :disabled="readonly"
                                :allow-empty="false"
                                :show-labels="false"
                            />
                            <template v-slot:append>
                                <button v-if="readonly" class="btn btn-secondary" @click.prevent="change_client_account">{{ $t('global.modifier') }} <font-awesome-icon :icon="['fal', 'edit']"/></button>
                                <template v-else>
                                    <button class="btn btn-secondary" @click.prevent="save_client_account">{{ $t('global.enregistrer') }} <font-awesome-icon :icon="['fal', 'save']"/></button>
                                    <button class="btn btn-secondary" @click.prevent="save_client_account_all(client_account.tiersclientaccount_id)">{{ $t('global.enregistrer_toute_comptas') }} <font-awesome-icon :icon="['fal', 'save']"/></button>
                                </template>
                                <span v-if="!readonly && client_account.is_duplicate" class="ml-2 pt-2">
                                    <font-awesome-icon :icon="['fal', 'exclamation-triangle']"/>
                                    {{ $t('toast.client_account_exists') }}
                                </span>
                            </template>
                        </b-input-group>
                    </div>
                    <div class="col" v-else>
                    </div>
                </div>
            </div>
		</div>
        <div class="box">
            <div v-if="tiers_copy.tiers_country == 75" class="form-group">
                <label>{{ $t('tiers.siret') }}</label>
                <e-copybox boxed classes="mb-3" :value.sync="tiers_copy.tiers_siret"></e-copybox>
                <div v-if="!siret_valid">
                    <b-alert show variant="warning" class="mt-2">
                        <font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
                        {{ $t('tiers.invalid_siret') }}
                    </b-alert>
                </div>
            </div>
            <div v-if="tiers_copy.tiers_country == 75" class="form-group">
                <label>{{ $t('tiers.siren') }}</label>
                <e-copybox boxed classes="mb-3" :value.sync="tiers_copy.tiers_siren"></e-copybox>
                <div v-if="!siren_valid">
                    <b-alert show variant="warning" class="mt-2">
                        <font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
                        {{ $t('tiers.invalid_siren') }}
                    </b-alert>
                </div>
            </div>
            <div class="form-group">
                <label> {{ vatAppelation }}
                    <span v-if="this.tiers_copy.tiers_vatcheck > 0" :class="tvaMessageColor">
                         - {{ tvaMessage }}
                    </span>
                </label>
                <e-copybox boxed classes="mb-3" :value.sync="tiers_copy.tiers_vat_number"></e-copybox>
                <div v-if="isVatEligible && tiers_copy.tiers_vat_number != '' && !vat_valid">
                    <b-alert show variant="warning" class="mt-2">
                        <font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
                        {{ $t('tiers.invalid_vat') }}
                    </b-alert>
                </div>
            </div>
            <div>
                <label>{{ $t('tiers.rib') }}</label>
                <div
                    class="form-group form-inline"
                    v-for="(rib) in tiers_copy.ribs"
                    :key="`tiersrib-${rib.tiersrib_id}`"
                >
                    <input type="text" class="form-control col-10" v-model="rib.tiersrib_content" :placeholder="getTrad('tiers.rib')">
                    <button class="btn btn-primary" @click.prevent="delete_rib(rib.tiersrib_id)"><font-awesome-icon :icon="['fal', 'trash']"/></button>
				</div>
				<div class="form-group form-inline">
					<button class="btn btn-primary" @click.prevent="add_rib">{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']"/></button>
				</div>
            </div>
        </div>

        <div class="text-center" ref="actionbuttons" v-show="saveButton">
            <button v-if="!processing" class="btn btn-secondary btn-rounded" @click.prevent="resetForm">{{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']"/></button>
            <button :disabled="processing" class="btn btn-primary btn-rounded" @click.prevent="checkForm">{{ $t('global.enregistrer') }} 
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse/>
                <font-awesome-icon v-else :icon="['fal', 'save']"/>
            </button>
        </div>
        <ModalTiersPappers ref="modal_pappers" @tiers="change"/>
    </div>
</template>

<script type="text/javascript">
import Common from '@/assets/js/common.js'
import Tools from '@/mixins/Tools.js'
import Phone from "@/mixins/Phone.js"
import Tiers from "@/mixins/Tiers.js"
import _debounce from 'lodash/debounce'

export default {
	name: 'Juridique',
	mixins: [Tools, Phone, Tiers],
	components: {
		ModalTiersPappers: () => import('@/components/Modals/ModalTiersPappers'),
	},
    props: {
        tiers: { type: Object, default: () => ({}) },
    },
	data() {
		return {
            tiers_copy: { },
			tiers_save: { },
            saveButton: false,
            processing: false,
            vat_code: [
            	{
            		'value': 'FD1V',
            		'label': this.getTrad('compta.form.fd1v'),
            	},
            	{
            		'value': 'FDVXP',
            		'label': this.getTrad('compta.form.fdvxp'),
            	},
            	{
            		'value': 'FDVEX',
            		'label': this.getTrad('compta.form.fdvex'),
            	}
            ],
            ribs: null,
            deleted_ribs: [],
			client_accounts: null,
			readonly: true,
            vat_valid: null,
            siret_valid: true,
            siren_valid: true,
			debounced_check_client_account: _debounce(this.check_client_account, 500)
		}
	},

	/* Initialisation du tableau */
	created: function() {
        this.initComponent()
	},

	methods: {
        async initComponent(){    
            if(this.tiers.tiers_id != undefined)
            {
                this.tiers_copy = this.deppCloneObj(this.tiers)

                const ribs = await this.loadRib(this.tiers.tiers_id);
                this.$set(this.tiers_copy, 'ribs', ribs)

                if(ribs.length == 0)
                {
                    this.add_rib()
                }

                const client_accounts = await this.loadClientAccount(this.tiers.tiers_id);
                await Common.asyncForEach(
                    client_accounts,
                    async (client_account) => {
                        client_account.tiersclientaccount_vat_code = this.vat_code.find(elem => elem.value == client_account.tiersclientaccount_vat_code)
                    }
                )
                this.$set(this.tiers_copy, 'client_accounts', client_accounts)

                this.tiers_save = this.deppCloneObj(this.tiers_copy)

                this.$root.$on('bv::modal::hidden', () => {
                    this.change()
                })
            }
        },

        add_rib()
        {
            this.tiers_copy.ribs.push({
                tiersrib_content: '',
                tiersrib_id: Common.getNegativeId()
            })
        },

        delete_rib(key)
        {
            const index = this.tiers_copy.ribs.findIndex(elem => key == elem.tiersrib_id)
            this.deleted_ribs.push(key)
            this.tiers_copy.ribs.splice(index, 1)
        },
        showSaveButton() {
            this.saveButton = true
            this.$nextTick(() => {
                this.scrollToElement()
            })
        },
        hideSaveButton() {
            this.saveButton = false
        },
        resetForm(){
            this.tiers_copy = this.deppCloneObj(this.tiers_save)
        },
        scrollToElement() {
            const el = this.$refs.actionbuttons;

            if (el) {
                el.scrollIntoView({behavior: 'instant'});
            }
        },
        async checkForm()
        {
            if (this.tiers_copy.tiers_country == 75){
                if (!this.siren_valid){
                    return this.failureToast('toast.invalid_siren')
                }
                if (!this.siret_valid){
                    return this.failureToast('toast.invalid_siret')
                }
                // Si on a que le SIRET, on peut en déduire le SIREN
                if (this.tiers_copy.tiers_siret && this.tiers_copy.tiers_siret != "" && (!this.tiers_copy.tiers_siren || this.tiers_copy.tiers_siren == "")){
                    this.tiers_copy.tiers_siren = this.tiers_copy.tiers_siret.substring(0,9)
                }
            }
            this.processing = true
            let result = null
            result = await this.saveJuridique(this.tiers_copy, this.deleted_ribs, this.vat_valid)
            await this.$sync.runOptionalWhishlist(['tiers'])
            if(result)
            {
                this.tiers_save = this.deppCloneObj(this.tiers_copy)
                this.successToast()
                if (this.siret_valid || this.siren_valid){
                    await this.interrogatePappers()
                }
                this.hideSaveButton()
                this.$cache.tiers = this.deppCloneObj(this.tiers_copy) // Pour le dashboard
            }
            this.processing = false
            
		},
        async interrogatePappers(){
			const tiers = this.tiers_save
            if (tiers.tiers_country == 75 && ((tiers.tiers_siret && tiers.tiers_siret.length == 14) || (tiers.tiers_siren && tiers.tiers_siren.length == 9))) {
					this.$refs.modal_pappers.openModal(tiers)
            }
		},
		change_client_account() {
			this.readonly = false
		},
		async save_client_account() {
			Common.asyncForEach(
				this.tiers_save.client_accounts,
				async (client_account) => {
					try {
						await this.saveClientAccountNumber(client_account.tiersclientaccount_id, client_account.tiersclientaccount_number, client_account.tiersclientaccount_vat_code.value)
						
						this.successToast()
						const client_accounts = await this.loadClientAccount(this.tiers.tiers_id);
						this.$set(this.tiers_copy, 'client_accounts', client_accounts)
					}
					catch {
						this.failureToast("error.UKE")
						this.tiers_save = this.deppCloneObj(this.tiers_copy)
					}
				}
			)

			this.readonly = true
		},
		async save_client_account_all(tiersclientaccount_id) {

            const modified_client_account = this.tiers_save.client_accounts.find(elem => elem.tiersclientaccount_id == tiersclientaccount_id)
            for(let client_account in this.tiers_save.client_accounts) {
                this.tiers_save.client_accounts[client_account].tiersclientaccount_number = modified_client_account.tiersclientaccount_number
                this.tiers_save.client_accounts[client_account].tiersclientaccount_vat_code.value = modified_client_account.tiersclientaccount_vat_code.value
                this.tiers_save.client_accounts[client_account].tiersclientaccount_vat_code.label = modified_client_account.tiersclientaccount_vat_code.label
            }

			Common.asyncForEach(
				this.tiers_save.client_accounts,
				async (client_account) => {
					try {
						await this.saveClientAccountNumber(client_account.tiersclientaccount_id, client_account.tiersclientaccount_number, client_account.tiersclientaccount_vat_code.value)
						
						this.successToast()
						const client_accounts = await this.loadClientAccount(this.tiers.tiers_id);
						this.$set(this.tiers_copy, 'client_accounts', client_accounts)
					}
					catch {
						this.failureToast("error.UKE")
						this.tiers_save = this.deppCloneObj(this.tiers_copy)
					}
				}
			)

			this.readonly = true
		},
		on_client_account_change(client_account, tiersclientaccount_number) {
			this.debounced_check_client_account(client_account, tiersclientaccount_number)
		},
		async check_client_account(client_account, tiersclientaccount_number) {
			await this.$storage.db.t('tiers_client_account')
			.then(table => {
				return table.where({
					tiersclientaccount_number: tiersclientaccount_number,
					tiersclientaccount_accountingplan: client_account.tiersclientaccount_accountingplan
				}).first()
			})
			.then(duplicated => {
				this.$set(client_account, 'is_duplicate', duplicated && duplicated.tiersclientaccount_id !== client_account.tiersclientaccount_id)
			})
		},
        checkCurrentVAT() {
            this.tiers_copy.tiers_vat_number = this.tiers_copy.tiers_vat_number.replace(/\W/g, '')
            this.vat_valid = this.checkVAT(this.tiers_copy.tiers_vat_number, this.tiers.tiers_country)
        },
        checkCurrentSiren() {
            // Si c'est vide on empêche pas la sauvegarde
            if (!this.tiers_copy.tiers_siren || this.tiers_copy.tiers_siren == ""){
                this.siren_valid = true
                return
            }
            this.tiers_copy.tiers_siren = this.tiers_copy.tiers_siren.replace(/\W/g, '')
            this.siren_valid = this.checkSiren(this.tiers_copy.tiers_siren)
        },
        checkCurrentSiret() {
            // Si c'est vide on empêche pas la sauvegarde
            if (!this.tiers_copy.tiers_siret || this.tiers_copy.tiers_siret == ""){
                this.siret_valid = true
                return
            }            
            this.tiers_copy.tiers_siret = this.tiers_copy.tiers_siret.replace(/\W/g, '')
            this.siret_valid = this.checkSiret(this.tiers_copy.tiers_siret)
        },
        change(){
            this.$emit("change")
        }
    },
    computed: {
        vatAppelation() {
            if ((this.$t('tiers.nom_vat.' + this.tiers.tiers_country) === 'tiers.nom_vat.' + this.tiers.tiers_country) || !this.isVatEligible) {
                return this.$t('tiers.num_tva')
            }
            return this.$t('tiers.num_tva') + ' (' + this.$t('tiers.nom_vat.' + this.tiers.tiers_country) + ')'
        },
        tvaMessage(){
			return this.tiers_copy.tiers_vatcheck == 1 ? this.$t('tiers.has_valid_vat') : this.$t('tiers.has_invalid_vat')
		},
        tvaMessageColor(){
			return this.tiers_copy.tiers_vatcheck == 1 ? 'text-success' : 'text-danger'
		},
        isVatEligible(){
            if(!this.tiers.tiers_country) return false
            // Si hors UE ne pas afficher le champ numéro de TVA
            const countryRegexes = this.getCountryVatRegex()
            if (countryRegexes[this.tiers.tiers_country]) return true
            return false
        }
    },
    watch: {
        tiers_copy: {
            handler(val){
                if(JSON.stringify(val) != JSON.stringify(this.tiers_save))
                {
                    this.showSaveButton()
                }
                else
                {
                    this.hideSaveButton()
                }
            },
            deep: true
        },
        'tiers_copy.tiers_vat_number': {
            handler(val) {
                if (val && this.isVatEligible) this.checkCurrentVAT()
            }
        },
        'tiers_copy.tiers_siren': {
            handler() {
                this.checkCurrentSiren()
            }
        },
        'tiers_copy.tiers_siret': {
            handler() {
                this.checkCurrentSiret()
            }
        }
    }
}
</script>
